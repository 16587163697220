import React from 'react';
import css from './ListingPage.module.css';
import { Heading } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

const SectionFilesMaybe = props => {
  const { publicData, isAuthenticated } = props;

  const files = publicData?.files || {};

  if (!files || !files.length) {
    return null;
  }

  if (!isAuthenticated) return null;
  
  return (
    <section className={css.filesSection}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.filesTitle" />
      </Heading>
      <div className={css.fileContainer}>
        {files.map((file, index) => (
          <a
            key={index}
            href={file.url}
            download={file.fileName}
            className={css.fileBox}
          >
            <div className={css.icon}>
              {/* SVG File Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
            </div>
            <div className={css.fileName}>{file.fileName}</div>
          </a>
        ))}
      </div>
    </section>
  );
};

export default SectionFilesMaybe;